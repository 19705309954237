<template>
  <div class="public_participation_page">
    <el-form inline size="mini">
      <el-form-item label="时间">
        <el-date-picker
          v-model="postdata.activityData"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="searchData">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      size="mini"
      border
      style="width: 100%;"
      :data="dataObj.list"
      :loading="postdata.loading"
    >
      <el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
      <el-table-column prop="Name" label="单位名称" align="center">
        <template slot-scope="{ row }">{{ row.Customer ? row.Customer.Name : '--' }}</template>
      </el-table-column>
      <el-table-column prop="recordTime" label="评论日期" align="center"></el-table-column>
      <el-table-column prop="Nickname" label="评论人" align="center">
        <template slot-scope="{ row }">{{ row.WxUser ? row.WxUser.Nickname : '--' }}</template>
      </el-table-column>
      <el-table-column prop="Mobile" label="联系电话" align="center">
        <template slot-scope="{ row }">{{ row.WxUser ? row.WxUser.Mobile : '--' }}</template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center">
        <template slot-scope="{ row }">
          <el-button type="text" size="mini" @click="showAddDialog('look', row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page_box">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="postdata.page"
        :page-sizes="[ 20, 40, 100]"
        :page-size="postdata.perPage"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataObj.total"
      ></el-pagination>
    </div>

    <!-- 新增弹窗 beginning -->
    <el-dialog :visible.sync="addPostdata.show" title="评论详情" width="500px">
      <el-form label-width="80px" ref="addPostdata">
        <el-form-item class="form_item_desc" label="描述">{{ addPostdata.Content }}</el-form-item>
        <el-form-item label="图片">
          <el-image
            style="width: 100px; height: 100px"
            :src="addPostdata.Pic"
            :preview-src-list="[addPostdata.Pic]"
          >
          <div slot="error" class="image-slot">
            暂无图片
          </div>
          </el-image>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 新增弹窗 end -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      postdata: {
        loading: false, // 列表数据加载状态
        page: 1,
        perPage: 10,
        startDate: "", // 开始时间
        endDate: "", // 结束时间
        activityData: [],
      },
      // 列表数据
      dataObj: {
        list: [],
        pages: 0,
        total: 0,
      },
      // 新增消息入参
      addPostdata: {
        show: false, // 是否显示新增弹窗
        Content: "", // 描述
        Pic: "", // 图片
      },
    };
  },
  mounted() {
    this.netGetMessageList(); // 获取消息列表
  },
  methods: {
    /**
     * @description 关闭弹窗
     * @param {String} name 数据对象名称
     */
    closeDialog(name) {
      this[name].show = false;
      this.resetForm(name);
    },
    /**
     * @description 显示弹窗
     * @param {String} type 显示类型 add添加；look查看
     */
    showAddDialog(type, data) {
      this.addPostdata.show = true;

      if (data) {
        this.addPostdata.Content = data.Content;
        this.addPostdata.Pic = data.Pic;
      }
    },
    /**
     * @description 获取消息列表
     */
    netGetMessageList() {
      this.postdata.loading = true;
      if (this.postdata.activityData && this.postdata.activityData.length > 0) {
        this.postdata.startDate = this.postdata.activityData[0] + " 00:00:00";
        this.postdata.endDate = this.postdata.activityData[1] + " 23:59:59";
      }
      this.$post("admin/queryCustomerReview", this.postdata, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          console.log(res);
          this.dataObj.list = res.data;
          this.dataObj.pages = res.pageInfo.TotalPage;
          this.dataObj.total = res.pageInfo.Total;
          this.postdata.loading = false;
        })
        .catch(() => {
          this.postdata.loading = false;
        });
    },
    /**
     * @description 查询
     */
    searchData() {
      this.postdata.page = 1;
      this.netGetMessageList();
    },

    /**
     * @description 每页条数
     */
    handleSizeChange(pageSize) {
      this.postdata.page = 1;
      this.postdata.perPage = pageSize;
      this.netGetMessageList();
    },

    /**
     * @description 页码
     */
    handleCurrentChange(page) {
      this.postdata.page = page;
      this.netGetMessageList();
    },
  },
};
</script>
<style lang="scss">
.public_participation_page {
  .page_box {
    padding: 30px 0;
    text-align: center;
  }

  .form_item_desc {
    .el-form-item__content {
      padding: 10px 0;
    }
  }
}
</style>

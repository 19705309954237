var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"public_participation_page"},[_c('el-form',{attrs:{"inline":"","size":"mini"}},[_c('el-form-item',{attrs:{"label":"时间"}},[_c('el-date-picker',{attrs:{"value-format":"yyyy-MM-dd","type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期"},model:{value:(_vm.postdata.activityData),callback:function ($$v) {_vm.$set(_vm.postdata, "activityData", $$v)},expression:"postdata.activityData"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-search","size":"mini"},on:{"click":_vm.searchData}},[_vm._v("查询")])],1)],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"size":"mini","border":"","data":_vm.dataObj.list,"loading":_vm.postdata.loading}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","align":"center","width":"50"}}),_c('el-table-column',{attrs:{"prop":"Name","label":"单位名称","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.Customer ? row.Customer.Name : '--'))]}}])}),_c('el-table-column',{attrs:{"prop":"recordTime","label":"评论日期","align":"center"}}),_c('el-table-column',{attrs:{"prop":"Nickname","label":"评论人","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.WxUser ? row.WxUser.Nickname : '--'))]}}])}),_c('el-table-column',{attrs:{"prop":"Mobile","label":"联系电话","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.WxUser ? row.WxUser.Mobile : '--'))]}}])}),_c('el-table-column',{attrs:{"label":"操作","fixed":"right","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text","size":"mini"},on:{"click":function($event){return _vm.showAddDialog('look', row)}}},[_vm._v("查看")])]}}])})],1),_c('div',{staticClass:"page_box"},[_c('el-pagination',{attrs:{"current-page":_vm.postdata.page,"page-sizes":[ 20, 40, 100],"page-size":_vm.postdata.perPage,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.dataObj.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_c('el-dialog',{attrs:{"visible":_vm.addPostdata.show,"title":"评论详情","width":"500px"},on:{"update:visible":function($event){return _vm.$set(_vm.addPostdata, "show", $event)}}},[_c('el-form',{ref:"addPostdata",attrs:{"label-width":"80px"}},[_c('el-form-item',{staticClass:"form_item_desc",attrs:{"label":"描述"}},[_vm._v(_vm._s(_vm.addPostdata.Content))]),_c('el-form-item',{attrs:{"label":"图片"}},[_c('el-image',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":_vm.addPostdata.Pic,"preview-src-list":[_vm.addPostdata.Pic]}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_vm._v(" 暂无图片 ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }